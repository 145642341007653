import React from 'react';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildAllPhotosPath, PhotoflipperModel } from 'client/data/models/photoflipper';
import { buildPreProdContentPath, buildReviewPath, EditorialReviewModel } from 'client/data/models/editorial-review';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { getCoreMedia } from 'site-modules/shared/utils/core-page/core-media';

const DEFAULT_PHOTO_SIZE = { width: 600, height: 400 };

export function coreMediaWrapper(WrappedComponent) {
  function WithCoreMediaWrapper({
    params,
    vehicle,
    isPreprod,
    photos,
    firstContent,
    editorialReview,
    editorialReviewHero,
    photoSize = DEFAULT_PHOTO_SIZE,
    ...restProps
  }) {
    if (!photos) {
      return null;
    }

    const coreMedia = getCoreMedia({
      params,
      vehicle,
      firstContent,
      photos,
      isPreprod,
      editorialReviewHero: editorialReview?.heroImage || editorialReviewHero,
      defaultSize: photoSize,
    });

    return (
      <WrappedComponent
        params={params}
        vehicle={vehicle}
        editorialReview={editorialReview}
        isPreprod={isPreprod}
        coreMedia={coreMedia}
        {...restProps}
      />
    );
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithCoreMediaWrapper.displayName = `WithCoreMediaWrapper(${wrappedComponentName})`;

  return WithCoreMediaWrapper;
}

export const stateToPropsConfig = {
  photos: bindToPath(
    ({ params, vehicle, photoSize }) =>
      buildAllPhotosPath({
        ...params,
        pageNum: 1,
        submodel: vehicle.submodels.name,
        imageSize: get(photoSize, 'width') || DEFAULT_PHOTO_SIZE.width,
      }),
    PhotoflipperModel
  ),
  firstContent: bindToPath(
    ({ vehicle }) => buildPreProdContentPath(getParamsFromVehicle(vehicle)),
    EditorialReviewModel
  ),
  editorialReviewHero: bindToPath(
    ({ params, editorialReview }) => !editorialReview && buildReviewPath(params),
    EditorialReviewModel,
    review => get(review, 'heroImage')
  ),
};

export const withCoreMediaWrapper = WrappedComponent =>
  connectToModel(coreMediaWrapper(WrappedComponent), stateToPropsConfig);
