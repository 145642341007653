import { map, compose } from 'lodash/fp';
import { extractMetadata } from './content';

// Curried iterattee-first pure fp functions for compose
export const getChild = childId => content => content.child(childId);

export const getChildren = content => content.children();

export const getAllMetadata = content => content.getAllMetadata();

export const getMetadataValue = metadataName => content => content.metadata(metadataName).value();

export const getMetadataByKeys = metadataKeys => content => extractMetadata(metadataKeys, content);

export const getChildrenAllMetadata = compose(
  map(getAllMetadata),
  getChildren
);

export const getChildrenOfChild = childId =>
  compose(
    getChildren,
    getChild(childId)
  );
