import { get } from 'lodash';
import { DFPManager } from '@edmunds/react-dfp';
import debounce from 'lodash/debounce';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { shouldRefreshAds } from 'client/actions/ads-refresh';
import { PHOTOFLIPPER_EVENTS } from 'site-modules/shared/constants/photoflipper/events';
import { RefreshRules } from 'client/site-modules/shared/components/ad-unit/utils/refresh-rules';
import { SLIDE_INDEX_360 } from 'site-modules/shared/constants/photoflipper/photoflipper';
import { getDelayTime, getEnableRefreshAds } from 'client/engagement-handlers/adhesion-ad-refresh-rate-limiter';

const REFRESH_TIME_VALUE = 500;
export const PHOTOFLIPPER_CAROUSEL = 'photoflippercarousel';
export const PHOTOFLIPPER_FIFTH_SLIDE = 'photoflipperFifthSlide';
export const PHOTOFLIPPER_EVERY_SLIDE = 'photoflipperEverySlide';
export const PHOTOFLIPPER_MODAL_OPEN_CREATIVE_ID = 'edm-entry-photoflipper-modal';
export const PHOTOFLIPPER_CLOSE_CREATIVE_ID = 'edm-entry-photoflipper';
const STICKY_BANNER_HEIGHT = 102;

export function handlePhotoFlipperOpen() {
  const eventObj = {
    event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
    event_data: {
      action_name: TrackingConstant.ACTION_WIDGET_VIEW,
      action_cause: TrackingConstant.ACTION_CAUSE_DRAWER_OPEN,
      creative_id: PHOTOFLIPPER_MODAL_OPEN_CREATIVE_ID,
    },
  };

  EventToolbox.fireTrackAction(eventObj);
}

export function handlePhotoFlipperClose() {
  const eventObj = {
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_PHOTO,
      subaction_name: TrackingConstant.CLOSE_PHOTOFLIPPER,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: PHOTOFLIPPER_CLOSE_CREATIVE_ID,
    },
  };

  EventToolbox.fireTrackAction(eventObj);
}

export function handleMobileFullscreenPhotoFlipperOpen() {
  const eventObj = {
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_PHOTO,
      subaction_name: TrackingConstant.OPEN_PHOTOFLIPPER,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: 'image_click',
      creative_id: 'photoflipper_fullscreen',
    },
  };

  EventToolbox.fireTrackAction(eventObj);
}

export function handleMobileFullscreenPhotoFlipperClose() {
  const eventObj = {
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_PHOTO,
      subaction_name: TrackingConstant.CLOSE_PHOTOFLIPPER,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: `${PHOTOFLIPPER_CLOSE_CREATIVE_ID}_fullscreen`,
    },
  };

  EventToolbox.fireTrackAction(eventObj);
}

export function handle360WidgetView({ carmaxFallback, photoInfo, slideIndex }) {
  const is360Available = !!photoInfo || !!carmaxFallback;

  if (slideIndex === SLIDE_INDEX_360 && is360Available) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
      event_data: {
        action_name: TrackingConstant.ACTION_WIDGET_VIEW,
        action_cause: TrackingConstant.ACTION_CAUSE_DRAWER_OPEN,
        creative_id: carmaxFallback ? 'edm-entry-carmax-360' : get(photoInfo, 'creativeId'),
      },
    });
  }
}

export function setupSwipeHandler(dispatch, getState) {
  let lastRefresh;
  return function handlePhotoflipperSwipe(event) {
    const currentSlide = get(event, 'detail.currentSlide', 0);
    const refreshZones = [PHOTOFLIPPER_CAROUSEL];
    const REFRESH_UNITS = [];
    RefreshRules.refreshOnRefreshZones.photoflippercarousel.forEach(adUnitId => REFRESH_UNITS.push(adUnitId));
    const delayTime = getDelayTime();
    const enableFireAdRefresh = getEnableRefreshAds(new Date().getTime(), lastRefresh, delayTime);

    if (currentSlide && currentSlide % 5 === 0) {
      refreshZones.push(PHOTOFLIPPER_FIFTH_SLIDE);
      RefreshRules.refreshOnRefreshZones[PHOTOFLIPPER_FIFTH_SLIDE].forEach(adUnitId => REFRESH_UNITS.push(adUnitId));
    }

    if (!isNaN(currentSlide)) {
      refreshZones.push(PHOTOFLIPPER_EVERY_SLIDE);
      RefreshRules.refreshOnRefreshZones[PHOTOFLIPPER_EVERY_SLIDE].forEach(adUnitId => REFRESH_UNITS.push(adUnitId));
    }
    if (shouldRefreshAds('', getState, true) && REFRESH_UNITS.length && enableFireAdRefresh) {
      DFPManager.refresh(...REFRESH_UNITS);
      lastRefresh = new Date().getTime();
    }
  };
}

export function listenerDebounceWrapper(listener, timeValue) {
  return debounce(event => {
    listener(event);
  }, timeValue);
}

export const handleMobileLandscapeMode = debounce(() => {
  const isMediaPortrait = window.matchMedia('(orientation: portrait)').matches;
  const photoflipperMobile = document.querySelector('.photoflipper-mobile .photo-details-container');
  const DEFAULT_PADDING_TOP = '66.67%';
  if (isMediaPortrait) {
    // Portrait orientation
    photoflipperMobile.style.paddingTop = DEFAULT_PADDING_TOP;
    photoflipperMobile.style.width = '';
  } else {
    // Landscape orientation
    const windowH = window.innerHeight;
    const windowW = window.innerWidth;
    const paddingTop = `${Math.round((windowH / windowW) * 100)}%`;
    const width = `${Math.round(((815 * windowH) / (543 * windowW)) * 100)}%`;
    photoflipperMobile.style.paddingTop = paddingTop;
    photoflipperMobile.style.width = width;
  }
}, 500); // debounce is needed for chrome iPhone

export async function checkForPfThumbHeight() {
  const selector = '.pf-v3 .photoflipper-thumbnails';
  const photoflipperThumbnails = document.querySelector(selector);
  if (!photoflipperThumbnails) {
    return false;
  }
  const isFullscreenMode = photoflipperThumbnails.classList.contains('d-none');
  if (isFullscreenMode) {
    return true;
  }
  let prevHeight = null;
  let currentHeight = photoflipperThumbnails.offsetHeight;
  let limiter = 0;
  while (currentHeight < 50 || currentHeight > 500 || (prevHeight !== currentHeight && limiter < 100)) {
    prevHeight = currentHeight;
    currentHeight = document.querySelector(selector).offsetHeight;
    limiter += 1;
    // eslint-disable-next-line no-await-in-loop
    await new Promise(resolve => requestAnimationFrame(resolve));
  }
  return true;
}

export function resizePfWidth() {
  const pfContent = document.querySelector('.pf-v3 .pf-v3-content');
  pfContent.classList.remove('fade-in');
  pfContent.setAttribute('style', '');
  checkForPfThumbHeight().then(() => {
    const windowHeight = window.innerHeight;
    const stickyBanner = document.querySelector('.photoflipper-modal.adhesion .sticky-banner');
    const stickyBannerHeight = stickyBanner ? STICKY_BANNER_HEIGHT : 0;

    const pf = document.querySelector('.pf-v3');
    const header = pf.querySelector('.pf-heading');
    const headerContent = header.querySelector('h2') || header.querySelector('h1');
    const categoryContent = pf.querySelector('.pf-categories');

    const headerHeight = header.offsetHeight;
    const categoryHeight = categoryContent.offsetHeight;
    const pfContentHeight = pfContent.offsetHeight + 16;

    const percent = Math.min(
      Math.floor(((windowHeight - headerHeight - categoryHeight - stickyBannerHeight) / pfContentHeight) * 100),
      100
    );
    const maxWidth = `max-width: ${percent - 1}%;`;
    headerContent.setAttribute('style', maxWidth);
    categoryContent.setAttribute('style', maxWidth);
    pfContent.setAttribute('style', maxWidth);
    pfContent.classList.add('fade-in');
  });
}

/**
 * Photoflipper events handler
 */
export const PhotoflipperEngagementHandler = {
  /**
   * Setups carousel events handler
   *
   * @param  {Function} dispatch Dispatch app redux store actions
   * @param  {Function} getState Gets redux state
   * @return {void}
   */
  init({ dispatch, getState }) {
    const swipeHandler = setupSwipeHandler(dispatch, getState);
    const listener = listenerDebounceWrapper(swipeHandler, REFRESH_TIME_VALUE);

    EventToolbox.on(PHOTOFLIPPER_EVENTS.SWIPE, listener);
  },
};
