import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { lazyLoad } from 'site-modules/shared/components/lazy-load/lazy-load';

const PhotoflipperModalLoader = lazyLoad({
  loader: () => import(/* webpackChunkName: "photoflipper-modal" */ './photoflipper-modal'),
  componentName: 'PhotoflipperModal',
});

export function PhotoflipperModalAsync(props) {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.isOpen && !isLoaded) {
      setLoaded(true);
    }
  }, [isLoaded, props.isOpen]);

  return isLoaded ? <PhotoflipperModalLoader {...props} /> : null;
}

PhotoflipperModalAsync.propTypes = {
  isOpen: PropTypes.bool,
};

PhotoflipperModalAsync.defaultProps = {
  isOpen: false,
};
